@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';
@import '../../mixins/box-shadow';

.edit-author-links {
  &-container {
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;
    width: fit-content;
  }

  &-body {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  &-item {
    display: flex;
    margin-top: 1rem;
  }

  &-label {
    align-items: left;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  &-input {
    @include type($color: copyDark, $font: normal, $size: small);
    width: 30rem;
    align-items: left;
    border: 0.05rem solid;
    border-radius: 1rem;
  }

  @include breakpoint(medium, max) {
    justify-content: left;
  }
}

.button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 8rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  align-items: center;
}

.icons-container {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.icons {
  padding: 0.5rem;
  border-radius: map-get($map: $spacing, $key: borderRadius) / 2;
  display: flex;
  background-color: lighten(map-get($map: $colors, $key: primary), 35%);
  transition: opacity 0.3s;

  svg {
    width: 2rem;
    height: 2rem;
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }
}

.edit-icon-container {
  width: 1rem;
  height: 1rem;
  position: relative;
  top: 0.5rem;
  right: -7.5rem;
  z-index: 100;
  cursor: pointer;
  margin-bottom: 1rem;

  // background on hover
  &:hover {
    background-color: whitesmoke;
    @include box-shadow();
  }
}
