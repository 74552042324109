@import '../../config';
@import '../../mixins/typography';

.container {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
}

.item-container {
  display: flex;
  margin-bottom: 1.5rem;

  &-small {
    margin-bottom: 0rem;
  }
}

.close-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.details-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.strike-thru {
  text-decoration: line-through;
  @include type($font: copyLight, $size: tiny);
  opacity: 0.7;
}

.close-icon {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.text {
  @include type($font: copy, $size: small);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  &-top {
    @include type($font: copy, $size: small);
  }

  &-seller {
    padding-bottom: 0.6rem;
    @include type($font: copy, $size: small);
  }

  &-image {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
}

.total {
  @include type($font: copyBold, $size: small);
}

.image-container {
  position: relative;
  align-self: center;
  cursor: pointer;

  img {
    width: 70px;
    height: 80px;
    object-fit: cover;
    border-radius: map-get($map: $spacing, $key: borderRadius) / 2;
    max-width: none;
  }
}

.details {
  margin: 0rem 1rem;
  flex: 1;
  align-self: center;
}

.title {
  @include type($font: copyBold, $size: base);
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.6rem;
}

.sold-wrapper {
  margin-left: 0.25rem;
  background-color: map-get($map: $colors, $key: yellow);
  @include type($font: copyBold, $size: small, $color: copyDark);
  padding: 0.25rem 0.5rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.buy-now-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.price-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pill-wrapper {
  margin: 0rem 1rem;
  display: flex;
}

.no-margin {
  margin: 0rem;
}

.mt-1 {
  margin-top: 0.2rem;
}

.pill-text {
  color: blue;
}

.sold {
  position: absolute;
  background-color: map-get($map: $colors, $key: yellow);
  width: 100%;
  text-align: center;
  top: 50%;
  padding: 0.1rem 0rem;
  @include type($font: copyBold, $size: small, $color: copyDark);
}

// For the recommended book component
.rec {
  &-wrapper {
    margin-bottom: 1.5rem;

    &-small {
      margin-top: 1rem;
      margin-bottom: 0rem;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    background-color: map-get($map: $colors, $key: primary);
    padding: 0.5rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  &-image {
    position: relative;
    cursor: pointer;
    width: 70px;
    height: 80px;

    img {
      width: 70px;
      height: 80px;
      object-fit: cover;
      border-radius: map-get($map: $spacing, $key: borderRadius) / 2;
      max-width: none;
    }
  }

  &-title {
    @include type($font: copyBold, $size: base, $color: light);
    padding-bottom: 0.6rem;
  }

  &-price {
    @include type($font: copy, $size: small, $color: light);
  }

  &-price-st {
    @include type($font: copyLight, $size: tiny, $color: light);
  }

  &-callout-title {
    @include type($font: copyBold, $size: base, $color: copyDark);
    padding-bottom: 0.5rem;
  }

  &-callout-body {
    @include type($font: copy, $size: small, $color: copy);
    padding-bottom: 0.25rem;
  }
}

.seller-link {
  @include type($font: copySemiBold, $size: small, $color: copyDark);

  &:hover {
    text-decoration: underline;
  }
}

.offer-applied {
  &-text {
    @include type($font: copy, $size: small, $color: green);
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;

    path {
      fill: map-get($map: $colors, $key: green);
    }
  }
}
