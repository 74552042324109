@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';
@import '../../mixins/box-shadow';

.author-img {
  border-radius: map-get($map: $spacing, $key: borderRadius);
  object-fit: cover;
  height: 12rem;
  width: 12rem;
  border-radius: 50%;

  @include breakpoint(medium, max) {
    height: 10rem;
    width: 10rem;
  }
}

.hidden {
  display: none;
}

.edit-icon-container {
  width: 1rem;
  height: 1rem;
  position: relative;
  top: 0.5rem;
  right: -7.5rem;
  z-index: 100;
  cursor: pointer;

  // background on hover
  &:hover {
    background-color: whitesmoke;
    @include box-shadow();
  }
}
