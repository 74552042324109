@import "../config";
@import "../mixins/typography";
@import "../mixins/link";

.link {
  display: block;

  a {
    @include link();
    margin-left: .35rem;
    display: inline-block;
  }
}
