@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/link';
@import '../mixins/scroll';

.profile-dropdown-container {
  position: relative;
}

.dropdown-container {
  position: relative;
}

.browse-dropdown-container {
  position: relative;
}

.browse-dropdown-container > div:nth-child(2) {
  position: absolute;
  top: 100%;
  left: 0;
}

.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  // width: 22rem;
  width: 80vw;
  height: 100vh;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out, visibility 0s linear 0.3s;
  z-index: 10000;
  background-color: map-get($map: $colors, $key: foreground);
  @include box-shadow();
  display: flex;
  flex-direction: column;
  // @include scrollY();
  padding-bottom: 4rem;
  overflow-y: auto;
  visibility: hidden;

  @include breakpoint(xsmall, max) {
    width: 80vw;
  }

  &.open {
    transform: translateX(0);
    right: 0;
    visibility: visible;
    transition: transform 0.3s ease-in-out;
  }

  &-icon {
    height: 2rem;
    width: 2rem;
    cursor: pointer;

    &-tt {
      position: absolute;
      right: -3rem;
      top: 1rem;
    }

    &-container {
      width: 100%;
      position: relative;
    }

    &-open {
      @include breakpoint(xx-large, max) {
        svg {
          path {
            fill: map-get($map: $colors, $key: foreground);
          }
        }
      }
    }
  }
}
.profile-dropdown {
  nav {
    // padding: 2rem;
    padding-top: 0;

    a {
      @include copy(small);
      cursor: pointer;
      display: inline-block;
      width: 100%;
      margin-left: 0;
      padding-top: 1rem;
      padding-bottom: 0;

      &:hover {
        @include link();
      }
    }
  }
}

.browse-menu-dropdown {
  // height: auto;

  nav {
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0;

    a {
      @include copy(small);
      cursor: pointer;
      display: inline-block;
      width: 100%;
      margin-left: 0;
      padding-top: 1rem;
      padding-bottom: 0;

      &:hover {
        @include link();
      }
    }
  }
}

.menu-item {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  @include type($size: small);
  font-size: 1rem !important;
}

.browse-dropdown-flex {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  @include type($size: small);
  font-size: 1rem !important;
}

.browse-menu-open {
  padding-bottom: 0.5rem !important;
}

.categories-container {
  nav {
    a {
      font-size: 1rem !important;
    }
  }
}

.browse-dropdown-flex h2 {
  margin: 0;
}

.bottom-menu-spacing {
  padding-bottom: 0.5rem !important;
}

.bold-text {
  @include type($size: small, $color: copyBold, $font: copyBold);
}

.with-border {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 0;
    border-bottom: 1px solid map-get($colors, borderLight);
  }
}

.profile-dropdown-user {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-bottom: 1rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 0;
    border-bottom: 1px solid map-get($colors, borderLight);
  }

  a {
    cursor: pointer;

    h2 {
      @include heading(small);
      margin-left: 1rem;
      white-space: nowrap;
    }

    // &:hover {
    //   h2 {
    //     color: map-get($map: $colors, $key: primary);
    //   }

    //   path {
    //     stroke: map-get($map: $colors, $key: primary);
    //   }

    //   @include link();
    // }
  }

  // svg {
  //   background: map-get($map: $colors, $key: borderLight);
  //   padding: 1rem;
  //   // width: 3.5rem;
  //   // height: 3.5rem;
  //   border-radius: 50%;
  // }

  img {
    min-width: 100%;
    min-height: 100%;
    border-radius: 50%;
  }

  &-photo {
    display: flex;
    align-items: center;
    position: relative;
  }

  img {
    min-width: 100%;
    min-height: 100%;
  }
}

#view-account-hover {
  color: map-get($map: $colors, $key: copyLight);

  &:hover {
    h2 {
      color: map-get($map: $colors, $key: primary) !important;
    }

    path {
      stroke: map-get($map: $colors, $key: primary) !important;
    }

    @include link();
  }
}

.profile-photo {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  @include breakpoint(large, min) {
    width: 2rem;
    height: 2rem;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.close-button {
  margin: 1rem;
  cursor: pointer;
  &:hover {
    path {
      stroke: map-get($map: $colors, $key: primary) !important;
    }
  }

  path {
    stroke: map-get($map: $colors, $key: copy) !important;
  }
}

.overlay {
  @include breakpoint(x-large, max) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(map-get($map: $colors, $key: copyDark), 0.4);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: background-color 0.3s ease;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}
