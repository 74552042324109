@import '../config';
@import '../mixins/typography';
@import '../mixins/link';

@mixin pageContentMaxWidth() {
  max-width: map-get($map: $spacing, $key: siteMaxWidth);
  margin: 0 auto;
  padding: 0 map-get($map: $spacing, $key: sitePadding);
}

.ambassador {
  overflow-x: hidden;

  header {
    padding: 0 map-get($map: $spacing, $key: sitePadding);
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}

.how {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  background-color: map-get($map: $colors, $key: primaryBackground);

  h1 {
    @include heading($font-size: jumbo);
    margin-bottom: 1rem;
  }

  h4 {
    @include type($size: medium, $font: copyBold, $color: copyDark);
    line-height: 1.5;
  }

  p {
    color: map-get($map: $colors, $key: copyDark);
    line-height: 1.3;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    gap: 4rem;

    @include breakpoint(medium, max) {
      flex-direction: column;
    }
  }

  &-step {
    position: relative;
    max-width: 20rem;
    height: 16rem;
    padding: 0 map-get($map: $spacing, $key: sitePadding);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    &::before {
      // refactor font color and size
      content: attr(data-step-number);
      position: absolute;
      top: -0.5rem;
      left: 2rem;
      // @include type($size: jumbo, $font: copyBold, $color: lightGray);
      color: rgba(59, 97, 235, 0.15);
      font-size: 6em;
      font-family: 'Gilroy-bold';
    }

    &-svg {
      display: flex;
      align-items: center;
      min-height: 9rem;
      z-index: 2;
    }
  }
}

.inspiration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0.5rem;

  h1 {
    @include heading($font-size: jumbo);
    margin-bottom: 1rem;
    text-align: center;
  }

  &-content {
    display: flex;
    max-width: 1330px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    gap: 4rem;
    flex-wrap: wrap;
  }

  @include breakpoint(medium, max) {
    .inspiration-content {
      flex-wrap: wrap;
    }

    @include breakpoint(small, max) {
      .inspiration-content {
        flex-direction: column;
      }
    }
  }
}

.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0.5rem;
  background-color: map-get($map: $colors, $key: primaryBackground);

  h1 {
    @include heading($font-size: jumbo);
    margin-bottom: 1rem;
    text-align: center;
  }
}

.callout {
  padding: 0 map-get($map: $spacing, $key: sitePadding);
  max-width: 1330px;
  margin: 3rem auto;
}
