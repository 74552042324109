@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/inputs';
@import '../../mixins/box-shadow';
@import '../../mixins/truncate';
@import '../../mixins/link';
@import '../../mixins/loading';

.shelf {
  &-container {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    padding: map-get($map: $spacing, $key: sitePadding);
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  &-empty,
  &-wishlist {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: map-get($map: $colors, $key: lighterGrey);
    height: 14rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  &-wishlist {
    svg {
      width: 4rem;

      path {
        fill: rgba(map-get($colors, primary), 0.5);
      }
    }
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    h3 {
      @include type($color: copyDark, $font: copyBold, $size: base);
      @include truncate(1);
      line-height: 1.5;
    }
  }

  &-info {
    display: flex;
  }
}

.seller {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-height: 5rem;
  flex: 1;

  &-profile-image {
    @include skeleton();
    border-radius: 50%;
    position: relative;
    min-width: 3rem;
    min-height: 3rem;
    width: 3rem;
    height: 3rem;
    border: 2px solid map-get($map: $colors, $key: foreground);

    img {
      border-radius: 50%;
    }
  }
}

.public-page {
  background-color: map-get($map: $colors, $key: foreground);
  border: 1px solid map-get($map: $colors, $key: borderLight);
  transition: box-shadow 0.3s ease;

  &-count {
    @include type($color: copy, $font: copy, $size: small);
  }

  &:hover {
    @include box-shadow;
  }
}
