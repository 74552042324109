@import '@/styles/config';
@import '@/styles/mixins/box-shadow';


.container {
  padding: map-get($map: $spacing, $key: sitePadding);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 0 auto;

  @include breakpoint(small, max) {
    flex-direction: column;
    align-items: left;
    text-align: left;
  }
}

.image {
  position: relative;
  height: 160px;
  width: 160px;

  img {
    object-fit: cover;
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }
}

.title {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-left: 1rem;

  @include breakpoint(small, max) {
    padding: 0;
    align-items: left;
    width: 100%;
    flex-shrink: 0;
  }
}


.stats {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  margin-top: 2.5rem;
  margin-left: 2rem;
  width: 250px;
  

  @include breakpoint(small, max) {
    padding: 0;
    padding-top: 1rem;
    width: 100%;
    align-items: left;
    margin-left: 0;
    margin-top: 1rem;
  }
}





