@import '../../config';

.icon {
  width: 80px;
  height: 80px;
  margin-right: map-get($map: $spacing, $key: sitePadding);

  @include breakpoint(medium, max) {
    width: 3.25rem;
    height: auto;

    img {
      width: 3.25rem;
      height: auto;
    }
  }
}

.container {
  background-image: url('/images/pb-logo-bg.svg');
  background-color: map-get($map: $colors, $key: foreground);
  background-repeat: no-repeat;
  background-position: 75% 45%;
  background-size: 30%;

  @include breakpoint(x-large, max) {
    background-position: 65% 45%;
  }

  @include breakpoint(large, max) {
    background-position: 55% 40%;
    background-size: 40%;
  }

  @include breakpoint(medium, max) {
    background-position: 95% 80%;
    background-size: 40%;
  }

  @include breakpoint(small, max) {
    background-position: 95% 100%;
    background-size: 40%;
  }
}
