@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';
@import '../../mixins/box-shadow';
@import '../../mixins/truncate';

.content {
  display: flex;
  flex-direction: column;

  h3 {
    @include type($color: copyDark, $font: heading, $size: large);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-card {
    padding: 1rem;
    background-color: map-get($map: $colors, $key: foreground);
    border-radius: map-get($map: $spacing, $key: borderRadius);
    @include box-shadow($withHover: true);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &-image {
    width: 100%;

    img {
      width: 100%;
      height: 11rem;
      border-radius: map-get($map: $spacing, $key: borderRadius);
      object-fit: cover;
    }
  }

  &-type {
    @include type($color: darkYellow, $font: heading, $size: small);
    letter-spacing: 1px;
  }

  h4 {
    min-height: 3rem;
    @include type($color: copyDark, $font: copyBold, $size: base);
    @include truncate(2);
    line-height: 1.4;
  }

  @include breakpoint(medium, max) {
    &-image img {
      height: 8rem;
    }
  }

  @include breakpoint(small, max) {
    &-container {
      grid-template-columns: 1fr;
    }
  }
}
