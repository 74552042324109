@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';
@import '../../mixins/box-shadow';

.header {
  @include type($color: copyDark, $font: heading, $size: jumbo);
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 2rem;
  display: flex;
  gap: 3rem;
  @include box-shadow;

  &-image {
    @include type($color: copyDark, $font: heading, $size: small);
    text-align: center;
  }

  &-name {
    @include type($color: copyDark, $font: heading, $size: small);
    text-align: center;
  }

  &-content {
    @include type($color: copyDark, $font: heading, $size: base);

    h1 {
      @include type($color: copyDark, $font: heading, $size: jumbo);
      margin-bottom: 0.5rem;
      line-height: 1.5;
    }
  }

  &-categories {
    @include type($color: copyDark, $font: heading, $size: base);
    padding: 0.5rem 0.5rem;
    margin-right: 0.5rem;
  }

  @include breakpoint(medium, max) {
    padding: 1rem;
    gap: 2rem;
    flex-direction: column;

    &-image {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }

    &-name {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }

    &-content {
      grid-template-columns: 1fr;
      row-gap: 2rem;

      h1 {
        width: 100%;
        text-align: center;
      }
    }

    &-categories {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }
}

.seller-stats {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 0;

  h3 {
    @include copy($font-size: small);
    margin-bottom: 0.5rem;
    white-space: nowrap;
  }

  span {
    @include heading();
    color: map-get($map: $colors, $key: primary);
  }
}

.category {
  &-title {
    @include type($color: copyDark, $font: heading, $size: base);
    margin-top: 1rem;
    margin-bottom: 0.2rem;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
  }
  &-pill {
    margin-top: 0.1rem;
    margin-right: 0.1rem;
  }
}

.series {
  &-title {
    @include type($color: copyDark, $font: heading, $size: base);
    margin-top: 1rem;
    margin-bottom: 0.2rem;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
  }
  &-pill {
    margin-top: 0.1rem;
    margin-right: 0.1rem;
  }
}

.publisher {
  &-title {
    @include type($color: copyDark, $font: normal, $size: base);
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  &-content {
    @include type($color: copyDark, $font: copy, $size: tiny);
    margin-left: 1rem;
  }
}
