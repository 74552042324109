@import '../../config/';

.container {
  display: flex;
  align-items: center;
}

.button {
  flex-shrink: 1;
  margin-left: 0.5rem;

  &-error {
    align-self: flex-start;
  }
}

.textInput {
  background-color: map-get($map: $colors, $key: foreground);
}