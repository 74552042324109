@import '../config';
@import '../mixins/typography';
@import '../mixins/inputs';
@import '../mixins/box-shadow';

.select {
  position: relative;

  select {
    @include box-shadow();
    @include input-reset();
    @include type($font: 'copy', $color: copyDark);
    position: relative;
    height: 100%;
    background-color: map-get($map: $colors, $key: foreground);
    border-radius: map-get($map: $spacing, $key: borderRadius);
    width: 100%;
    border: 1px solid map-get($map: $colors, $key: borderLight);
    padding: 1rem 1.25rem;
    padding-right: 3rem;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  &--transparent {
    background-color: transparent;
  }

  svg {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.options {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: map-get($map: $colors, $key: primary);
  z-index: 2;

  li {
    padding: 0.25rem map-get($map: $spacing, $key: sitePadding);
  }
}
