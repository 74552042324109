@import "../config";

.spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border: 0.25rem solid map-get($map: $colors, $key: background);
  border-radius: 50%;
  border-top-color: map-get($map: $colors, $key: primary);
  animation: spin 0.8s ease-in-out infinite;
  -webkit-animation: spin 0.8s ease-in-out infinite;

  &--small {
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    border-width: 0.2rem;
  }

  &--large {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-width: 0.3rem;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
