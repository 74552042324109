@import '../config';
@import '../mixins/typography';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.item {
  @include type($size: small, $font: copy, $color: copy);

  &-link {
    &:hover {
      @include type($size: small, $font: copy, $color: primary);
    }
  }
}
