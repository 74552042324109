@import '../../config';
@import '../../mixins/typography';

.tags {
  width: 100%;
  // margin-top: 2rem;

  h2 {
    @include heading();
    margin-bottom: 0.5rem;
  }

  &-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &-pill {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    word-break: break-word;
  }
}
