@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/link';

.pill {
  @include copy(small);
  background-color: $grey-300;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid map-get($map: $colors, $key: background);

  &--secondary {
    background-color: map-get($map: $colors, $key: foreground);
    border: 1px solid map-get($map: $colors, $key: foreground);
  }

  &--tiny {
    @include copy(xtratiny);
    padding: 0.2rem 0.6rem;
  }

  &--small {
    @include copy(tiny);
    padding: 0.25rem 0.75rem;
  }

  &--large {
    flex-direction: column;
    padding: 1rem;
  }

  &--link {
    transition: all 0.25s;
  }

  &--active {
    background-color: map-get($map: $colors, $key: background);
    color: map-get($map: $colors, $key: primary);
    border: 1px solid map-get($map: $colors, $key: primary);
  }

  &--loading {
    opacity: 0.75;
  }

  &--shadow {
    @include box-shadow();
  }

  &--outline {
    border: 1px solid map-get($map: $colors, $key: borderLight);
  }

  &--green {
    background-color: lighten(map-get($map: $colors, $key: green), 40%);
    border: 1px solid lighten(map-get($map: $colors, $key: green), 40%);
  }

  &--text-primary {
    color: map-get($map: $colors, $key: primary);
  }

  &--text-copyDark {
    color: map-get($map: $colors, $key: copyDark);
  }

  &--blue {
    background-color: map-get($map: $colors, $key: primary);
    color: map-get($map: $colors, $key: foreground);
    border: 1px solid map-get($map: $colors, $key: primary);

    &:hover {
      background-color: darken(map-get($map: $colors, $key: primary), 5%);
      border-color: darken(map-get($map: $colors, $key: primary), 5%);
    }
  }
}

.pill.pill--link {
  &:hover {
    background-color: darken(map-get($map: $colors, $key: foreground), 5%);
    border: 1px solid darken(map-get($map: $colors, $key: foreground), 5%);
  }

  &:active {
    background-color: darken(map-get($map: $colors, $key: foreground), 7%);
    border: 1px solid darken(map-get($map: $colors, $key: foreground), 7%);
  }
}

.pill--secondary.pill--link {
  &:hover {
    background-color: darken(map-get($map: $colors, $key: background), 5%);
    border: 1px solid darken(map-get($map: $colors, $key: background), 5%);
  }

  &:hover {
    background-color: darken(map-get($map: $colors, $key: background), 7%);
    border: 1px solid darken(map-get($map: $colors, $key: background), 7%);
  }
}

.pill--active.pill--link {
  &:hover {
    background-color: darken(map-get($map: $colors, $key: primary), 5%);
    border: 1px solid darken(map-get($map: $colors, $key: primary), 5%);
  }

  &:hover {
    background-color: darken(map-get($map: $colors, $key: primary), 7%);
    border: 1px solid darken(map-get($map: $colors, $key: primary), 7%);
  }

  &--light {
    background-color: map-get($map: $colors, $key: light);
    border: 1px solid map-get($map: $colors, $key: light);
  }
}

.close-icon-visible {
  display: none;

  &:hover {
    display: block;
  }
}

.close-icon {
  .close-icon-container {
    display: none;
  }

  &:hover {
    .close-icon-container {
      margin: 0rem 0.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }
}

.icon {
  display: contents;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    margin-right: 0.5rem;
  }

  &--large {
    margin-right: 0rem;
  }
}
