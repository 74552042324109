@import './reset';
@import './typography';
@import '../config';

:root {
  --primary: #{map-get($map: $colors, $key: primary)};
  --foreground: #{map-get($map: $colors, $key: foreground)};
  --background: #{map-get($map: $colors, $key: background)};
  --copy: #{map-get($map: $colors, $key: copy)};
  --copyLight: #{map-get($map: $colors, $key: copyLight)};
  --copyDark: #{map-get($map: $colors, $key: copyDark)};
  --grey: #{map-get($map: $colors, $key: grey)};
  --lightGrey: #{map-get($map: $colors, $key: lightGrey)};
  --light: #{map-get($map: $colors, $key: light)};
  --borderLight: #{map-get($map: $colors, $key: borderLight)};
  --yellow: #{map-get($map: $colors, $key: yellow)};
  --green: #{map-get($map: $colors, $key: green)};
  --lighterGrey: #{map-get($map: $colors, $key: lighterGrey)};
  --error: #{map-get($map: $colors, $key: error)};

  // Used to text notched on devices
  --safeAreaTop: env(safe-area-inset-top);
  --safeAreaBottom: env(safe-area-inset-bottom);
}

a {
  color: inherit;
  text-decoration: inherit;
}

body {
  font-family: map-get($map: $fonts, $key: copy);
  font-display: optional;
  background-color: map-get($map: $colors, $key: background);
}

main {
  min-height: calc(100vh - map-get($map: $spacing, $key: mobileNavHeight));

  @include breakpoint(large, min) {
    padding-bottom: 0;
  }
}

.intercom-lightweight-app {
  display: none;
}

.content {
  transition: opacity 0.3s;
}

// Used when the modal is visible to disable scroll on the layout
.modal-visible {
  max-height: 100vh;
  overflow: initial;
}

.admin-editing {
  width: calc(100vw - #{map-get($map: $spacing, $key: adminSidebarWidth)});
  margin-left: map-get($map: $spacing, $key: adminSidebarWidth);
}

.loading {
  opacity: 0.25;
}