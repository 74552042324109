@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/inputs';

$label-space: 0.25rem;

.input {
  @include input();
  width: 100%;
  resize: none;
  background-color: map-get($map: $colors, $key: foreground);
}

.message-label {
  margin-top: $label-space;
  display: inline-block;
  position: relative;
  @include type($font: copy, $size: small, $color: copy);
}

.error-label {
  margin-top: $label-space;
  display: inline-block;
  position: relative;
  @include copy();
  @include type($font: copy, $size: small, $color: yellow);
}

.input-disabled {
  background-color: map-get($map: $colors, $key: background);
}