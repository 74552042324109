@import '@/styles/config';
@import '@/styles/mixins/_animate_pulse';


.layoutContainer {
  @include breakpoint(large, max) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-top: 0;
  }
}

.container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: .5rem;
  margin-bottom: 1.5rem;
  grid-template-rows: auto;
  grid-template-areas:
    'productImage'
    'sidebar'
    'productDetails';
  clear: both;

  @include breakpoint(large, min) {
    grid-template-columns: 6fr 4fr;
    grid-template-areas:
      'productImage sidebar'
      'productDetails sidebar';
  }

}

.productImage {


  position: relative;
  grid-area: productImage;
  width: 100%;
  min-width: 0;

  @include breakpoint(large, max) {
    width: 100vw;
  }

}

.sidebar {
  grid-area: sidebar;
  align-self: start;
  position: sticky;
  top: 9rem;
  bottom: 0;

  @include breakpoint(large, max) {
    position: static;
  }

  @include breakpoint(small, max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.productDetails {
  grid-area: productDetails;

  @include breakpoint(large, max) {
    padding-left: $sitePadding;
    padding-right: $sitePadding;
  }
}

.additionalContent {
  @include breakpoint(large, max) {
    padding-left: $sitePadding;
    padding-right: $sitePadding;
  }
}

.topContent {
  @include breakpoint(large, max) {
    padding-left: $sitePadding;
    padding-right: $sitePadding;
  }
}

// Skeleton

.imageSectionSkeleton {
  height: 600px;
  background-color: $primary-100;
  @include animate-pulse;
  border-radius: $borderRadius;
}

.sidebarSkeleton {
  height: 600px;
  background-color: $primary-100;
  @include animate-pulse;
  border-radius: $borderRadius;
}

.productDetailsSkeleton {
  height: 600px;
  background-color: $primary-100;
  @include animate-pulse;
  border-radius: $borderRadius;
}

.additionalContentSkeleton {
  height: 600px;
  background-color: $primary-100;
  @include animate-pulse;
  border-radius: $borderRadius;
}

.sidebarCard {
  width: 100%;
  padding: $sitePadding $sitePadding * 2;

  @include breakpoint(large, max) {
    border-radius: 0;
    box-shadow: none !important;
    margin-bottom: 1rem;
    width: 100vw !important;
    padding: ($sitePadding / 2) ($sitePadding);
  }
}