@import "../config";
@import "../mixins/typography";
@import "../mixins/box-shadow";

.container {
  @include box-shadow();
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background-color: map-get($colors, foreground);
  width: auto;
  border-radius: map-get($spacing, "borderRadius");
  z-index: 1;

  &-left {
    left: 0;
    right: auto;
  }

  @include breakpoint(large, min) {
    &-fixed-small {
      width: 25rem;
    }

    &-fixed-med {
      width: 35rem;
    }

    &-fixed-large {
      width: 45rem;
    }
  }


}