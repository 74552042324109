@import "../../config";
@import "../../mixins/typography";
@import "../../mixins/box-shadow";

.container {
  @include type($font: copy, $size: base, $color: copy);
  display: grid;
  grid-template-columns: 1rem auto;
  gap: 0.75rem;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.container input {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15rem;
  height: 1.15rem;
  border: 0.15rem solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.container input::before {
  content: "";
  width: 0.65rem;
  height: 0.65rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em map-get($map: $colors, $key: foreground);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.container input:checked {
  transition: 120ms transform ease-in-out;
  background-color: map-get($map: $colors, $key: green);
  border: none;
}

.container input:checked::before {
  transform: scale(1);
}
