@import '@/styles/config';
@import '@/styles/mixins/box-shadow';
@import '@/styles/mixins/typography';
@import '@/styles/mixins/inputs';

.container {
  padding: map-get($map: $spacing, $key: sitePadding);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 0 auto;

  @include breakpoint(small, max) {
    flex-direction: column;
    align-items: left;
    text-align: left;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.inputGroup {
  display: flex;
  align-items: center;
  position: relative;
}

.inputBox {
  flex: 1;
}

.button {
  width: 100%;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 2rem;

  &:hover:not(:disabled) {
    background-color: var(--primary-color-dark);
  }

  &:disabled {
    background-color: var(--background-disabled);
    cursor: not-allowed;
  }
}

.suggestions {
  z-index: 1000;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  overflow: hidden;
}

.suggestionItem {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: white; // Set background to white
  border-radius: map-get($map: $spacing, $key: borderRadius);
  border: none;
}

.suggestionItem:hover {
  background-color: #f0f0f0; // Set hover background to light gray
}

.suggestionItem:hover {
  background-color: #f0f0f0;
}

.suggestion-item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  overflow: hidden;
  background: map-get($map: $colors, $key: background);
}

.suggestion-item-image img {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  overflow: hidden;
  background: map-get($map: $colors, $key: background);
}

.suggestion-info {
  display: flex;
  flex-direction: column;
}

.suggestion-info h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.suggestion-info h4 {
  margin: 0;
  font-size: 0.875rem;
  color: #666;
}

.iconButton {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
  }

  .button {
    max-width: 200px;
    margin-left: auto;
    margin-top: 2rem;
  }
}

.suggestionItem.highlighted {
  background-color: #e0e0e0; // Change to a light gray or any color you prefer
}
