@import '../config';
@import '../mixins/typography';
@import '../mixins/inputs';
@import '../mixins/link';

.facets {
  width: 100%;
  margin-top: 1rem;
}

.facet {
  width: 100%;
  margin-bottom: 1.5rem;

  &-title-container {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 0.5rem 0;
  }

  &-title {
    @include heading($font-size: base);
    line-height: 1.4;

    span {
      @include type($size: tiny);
      margin-left: 0.5rem;
      padding: 0.1rem 0.75rem;
      border-radius: map-get($map: $spacing, $key: borderRadius);
      background-color: map-get($map: $colors, $key: background);
    }
    
    &-search-options {
      margin-bottom: -1rem;
      @include heading($font-size: base);
    }
  }

  @mixin title-link-styles {
    @include copy($font-size: small);
    @include link();
    margin-left: .75rem;
  }

  &-title-link {
    @include title-link-styles();

    &-see-all-left {
      @include title-link-styles();
      margin-left: 0;
    }
  }

  &-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    @include checkbox();

    label span {
      padding: 0 0.25rem;
      @include type($font: copy, $size: small, $color: copy);
      line-height: 1.6;

      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }
  }

  &-tags-container {
    display: flex;
    flex-wrap: wrap;

    span,
    a {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &-format-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    a {
      width: 100%;
    }
  }

  &-inputs {
    display: flex;
    align-items: center;

    button {
      margin-left: 0.5rem;
      width: auto;
    }
  }

  &-state-options-desktop {
    display: flex;
    flex-direction: row;

    @include breakpoint(small, max) {
      display: none
    }
  }

  &-state-options-column-override {
    @include breakpoint(small, max) {
      display: flex !important;
      flex-direction: row !important;
    }
  }

  &-state-options-mobile {
    display: none;

    @include breakpoint(small, max) {
      display: block;
    }
  }
}

.bottom-footer-container {
  position: sticky;
  bottom: 0;
  margin-right: -1rem;
  margin-left: -1rem;
  padding: 1rem;
  background-color: map-get($map: $colors, $key: foreground);
  border-top: 1px solid map-get($map: $colors, $key: light);
  z-index: 999;
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  color: map-get($map: $colors, $key: copy);
  margin-bottom: 2rem;
}

.options-container {
  padding-top: 1rem;
}

.options-subtext {
  @include type($font: copy, $size: small, $color: copy);
  margin-top: 0.5rem;
}