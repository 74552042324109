@import '../config';
@import '../mixins/box-shadow';

.container {
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.container.shadow {
  @include box-shadow();
}

.white {
  background-color: $grey-100;
}

.primary {
  background-color: $primary-500;
}

.yellow {
  background-color: $warning-300;
}

.tertiary {
  background-color: map-get($map: $colors, $key: borderLight);
}
