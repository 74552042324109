@import '../config';
@import '../mixins/typography';
@import '../mixins/scroll';
@import "../mixins/box-shadow";

.container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  padding: map-get($map: $spacing, $key: sitePadding);
  background-color: map-get($map: $colors, $key: foreground);
  z-index: 50;
  animation-name: fadeInDown;
  animation-duration: 0.25s;
  border-radius: map-get($spacing, 'borderRadius');
  @include box-shadow();
}

@keyframes fadeInDown {
  from {
    bottom: 0rem;
  }

  to {
    bottom: 2rem;
  }
}

.content {
  position: relative;
  width: 15rem;
  background-color: map-get($colors, foreground);
  border-radius: map-get($spacing, 'borderRadius');
  margin-left: map-get($map: $spacing, $key: sitePadding);
}

.close-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.title {
  @include heading($font-size: small);
  background-color: map-get($colors, foreground);
  text-align: left;
}

.message {
  @include copy($font-size: tiny);
  margin-top: 0.5rem;
  text-align: left;
}

.button {
  margin-left: map-get($map: $spacing, $key: sitePadding);
  ;
}