@import '../config/';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/link';
@import '../mixins/truncate';
@import '../mixins/loading';

.book-image {
  @include skeleton();
  border-radius: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.25s;
  overflow: hidden;
  grid-area: main;

  &:hover {
    opacity: 0.8;
  }

  &--secondary {
    grid-area: secondary;
  }

  &--tertiary {
    grid-area: tertiary;
  }

  &-thumbnail {
    object-fit: cover;
    min-height: 100%;
  }
}

.image-container {
  display: grid;
  height: 14rem;
  gap: map-get($map: $spacing, $key: sitePadding) * 0.2;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  margin-bottom: 1rem;
  overflow: hidden;

  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'main main'
    'main main';

  &.two-books {
    grid-template-areas:
      'main secondary'
      'main secondary';
  }

  &.three-books {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'main main secondary'
      'main main tertiary';
  }
}
