.image {
  width: 100%;
  height: auto;
  transition: opacity 0.25s;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.loaded {
  opacity: 1;
}