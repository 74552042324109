@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';

.footer {
  padding: 3rem;
  background-color: map-get($map: $colors, $key: primary);

  ul {
    list-style: none;
  }

  &-container {
    min-height: 5rem;
    max-width: map-get($map: $spacing, $key: siteMaxWidth);
    padding: 0 map-get($map: $spacing, $key: sitePadding);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-main-content {
    max-width: map-get($map: $spacing, $key: siteMaxWidth);
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    // For logo to prevent shrinking
    > :nth-child(1) {
      flex-shrink: 0;
    }
  }

  &-bottom {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid map-get($map: $colors, $key: lightGray);
  }

  &-legal {
    @include type($font: copyLight, $size: tiny, $color: light);
    display: flex;
    gap: 2rem;
  }

  @include breakpoint(large, max) {
    &-main-content {
      flex-direction: column;
      justify-content: flex-start;
      gap: 2rem;
    }
  }

  @include breakpoint(medium, max) {
    padding: 3rem 1rem;

    &-bottom {
      align-items: center;
      flex-direction: column-reverse;
      gap: 2rem;
    }

    &-legal {
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      text-align: center;
    }
  }

  @include breakpoint(small, max) {
    &-legal-copyright {
      width: 100%;
    }
  }
}

.first-col {
  margin-right: 2rem;
}

.links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;

  @include breakpoint(small, max) {
    grid-template-columns: repeat(2, 1fr);
  }

  li {
    margin-bottom: 1rem;
  }

  &-heading {
    @include type($font: copyBold, $color: foreground);
    line-height: 1.5;
    margin-bottom: 1rem;
    white-space: nowrap;
  }

  &-label {
    @include type($font: copy, $color: foreground);
    transition: 0.25s all;
    line-height: 1.5;

    &:hover {
      @include type($font: copy, $color: copyLight);
    }
  }

  &-badges {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 1rem;

    svg {
      width: 100%;
      max-width: 8rem;
    }
  }
}

.icons {
  @include type($size: base, $font: copyBold, $color: foreground);
  display: flex;
  justify-content: flex-end;
  gap: 1.2rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    opacity: 0.7;
    transition: 0.25s all;

    &:hover {
      path {
        fill: map-get($map: $colors, $key: copyLight);
      }
      opacity: 1;
    }
  }
}
