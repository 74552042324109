@import "../config";
@import "../mixins/typography";
@import "../mixins/box-shadow";
@import "../mixins/link";

$slider-height: 0.25rem;
$handle-size: 1.25rem;

// Rheostat classes are hard coded to the elements so we have to globally define their definition.
// They are placed in this file so that they are still scoped to this files import
.slider {
  h2 {
    margin-bottom: 2rem;
  }

  :global(.rheostat) {
    width: calc(100% - $handle-size);
    position: relative;
    overflow: visible;

    :global(.DefaultProgressBar_progressBar) {
      background-color: map-get($map: $colors, $key: primary);
      position: absolute;
    }

    :global(.DefaultProgressBar__vertical) {
      width: 24px;
      height: 100%;
    }

    :global(.DefaultProgressBar_progressBar) {
      position: absolute;
    }

    :global(.DefaultProgressBar_background__horizontal) {
      height: $slider-height;
      top: 0px;
    }

    :global(.DefaultHandle_handle) {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      outline: none;
      z-index: 2;
    }

    :global(.DefaultHandle_handle__horizontal) {
      margin-left: -12px;
    }

    :global(.DefaultHandle_handle__horizontal:before) {
      top: 7px;
      height: $slider-height;
      width: 1px;
      left: 10px;
    }

    :global(.DefaultHandle_handle__horizontal:after) {
      top: 7px;
      height: $slider-height;
      width: 1px;
      left: 13px;
    }

    :global(.DefaultHandle_handle__vertical) {
      margin-top: -12px;
      left: -10px;
    }

    :global(.DefaultHandle_handle__vertical:before) {
      top: 10px;
    }

    :global(.DefaultHandle_handle__vertical:after) {
      top: 10rem;
      left: 8px;
      height: 1px;
      width: 10px;
    }

    :global(.DefaultHandle_handle__disabled) {
      border-color: #dbdbdb;
    }

    :global(.DefaultBackground) {
      background-color: lighten(map-get($map: $colors, $key: primary), 25%);
      height: $slider-height;
      width: 100%;
      position: relative;
      border-radius: 50px;
    }

    :global(.DefaultBackground_background__horizontal) {
      height: $slider-height;
      width: calc(100% + $handle-size);
    }

    :global(.DefaultBackground_background__vertical) {
      width: 15px;
      top: 0px;
      height: 100%;
    }

    :global(.handleContainer) {
      position: relative;
    }

    // .rheostat {
    //   position: relative;
    //   overflow: visible;
    // }
    // @media (min-width: 1128px) {
    //   .autoAdjustVerticalPosition {
    //     top: 12px;
    //   }
    // }
    // .rheostat__vertical {
    //   height: 100%;
    // }
    // .rheostat_background {
    // }
    // .rheostat_background__horizontal {
    // }
    // .rheostat_background__vertical {
    // }
    // .DefaultHandle_handle:focus {
    // }
    // .DefaultHandle_handle:after {
    // }
    // .DefaultHandle_handle:before {
    // }
    // .DefaultProgressBar_progressBar__vertical {
    // }
    // .DefaultProgressBar_background__vertical {
    // }
  }
}

.handle {
  cursor: grab;
  position: relative;

  span {
    @include type($size: tiny, $font: copyBold);
    background-color: map-get($map: $colors, $key: foreground);
    border-radius: 1rem;
    color: white;
    padding: 0.5rem;
    position: absolute;
    bottom: 1rem;
    text-align: center;
    transform: translateX(#{calc(-50% + 0.75rem)});
    border: 1px solid map-get($map: $colors, $key: primary);
    color: map-get($map: $colors, $key: primary);
  }
}

.background {
  height: 10rem;
  width: 100%;
  display: flex;
}

.button {
  cursor: grab;
  width: $handle-size;
  height: $handle-size;
  min-width: $handle-size;
  min-height: $handle-size;
  border-radius: 50%;
  background-color: map-get($map: $colors, $key: primary);
  outline: none;
  border: 2px solid map-get($map: $colors, $key: foreground);
  top: -0.7rem;
  z-index: 2;
  
  @include breakpoint(large, max) {
    width: $handle-size * 1.5;
    height: $handle-size * 1.5;
    min-width: $handle-size * 1.5;
    min-height: $handle-size *1.5;
    top: -1rem
  }
}
