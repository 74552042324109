@import '../config';
@import '../mixins/typography';

.container {
  max-width: map-get($map: $spacing, $key: siteMaxWidth);
  padding: 0 map-get($map: $spacing, $key: sitePadding);
  margin: 1rem auto;
}

.facets {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include breakpoint(large, max) {
    flex-direction: column;
    align-items: center;
    
  }

  &.extractedFacets {
    
    @include breakpoint(large, max) {
      align-items: flex-start
      
    }
  }

  @include breakpoint(large, min) {
    flex-direction: row;
  }

  .paragraph {
    @include type($size: base, $font: copySemiBold);

    @include breakpoint(large, max) {
      text-align: left;
      width: 100%;
    }

    &-span {
      @include type($size: base, $font: copy);
    }
  }


  > :last-child {
    width: auto;
  }

  &-buttons {
    display: flex;
    justify-content: space-between;

    > :first-child {
      margin-right: 1rem;
    }

    // > :first-child,
    > :last-child {
      max-width: 203px;
      min-width: 203px;

      @include breakpoint(small, max) {
        max-width: 170px;
        min-width: 170px;
      }

      @include breakpoint(xxsmall, max) {
        max-width: 150px;
        min-width: 150px;
      }

    }

  }

  .searchAndButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(large, max) {
      flex-direction: column;
    }

    @include breakpoint(large, min) {
      flex-direction: row;
    }

    .searchInputMobile {
      @include breakpoint(medium, min) {
        display: none;
      }
    }
  }
}

.bookstore-facets {
  @include breakpoint(large, max) {
    flex-direction: row; // Resetting facets default
  }

  @include breakpoint(medium, max) {
    flex-direction: column;
    align-items: center;
  }
}

.grid {
  width: 100%;
  display: grid;
  grid-gap: map-get($map: $spacing, $key: sitePadding);

  > :nth-child(odd),
  :nth-child(even) {
    max-width: none;
    min-width: 0;
  }

  grid-template-columns: repeat(2, 1fr);

  @include breakpoint(xsmall, min) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint(small, min) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint(medium, min) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include breakpoint(x-large, min) {
    grid-template-columns: repeat(6, 1fr);
  }

  &--wide {
    display: grid;
    grid-gap: map-get($map: $spacing, $key: sitePadding);
    grid-template-columns: repeat(1, 1fr);

    @include breakpoint(small, min) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint(medium, min) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(x-large, min) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.no-results {
  height: 90vh;
  max-height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-image-container {
    position: relative;
    width: 25rem;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 90vw;
    max-height: 90vw;
    margin: 0 auto;

    span {
      overflow: visible !important;
    }

    &-book {
      opacity: 1;
    }

    &-magnify {
      opacity: 0.9;
      animation: rotate 8s linear infinite;
      top: 3rem !important;
      left: 1rem !important;
      overflow: visible !important;
      z-index: 2;
    }
  }

  h2 {
    @include heading();
    text-align: center;
    margin-top: 1rem;
  }

  button {
    margin-top: 1rem;
    max-width: 20rem;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg) translateX(1rem) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(1rem) rotate(-360deg);
  }
}

.savedSearchContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-top: 1rem;
  color: map-get($map: $colors, $key: primary);
}

.resultsCountContainer {
  margin-right: 0.5rem;

  @include breakpoint(large, max) {
    margin-right: 0rem;
  }
}

.leftSideOptions {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include breakpoint(large, max) {
    margin-bottom: 0.5rem;
    width: 100%;
    justify-content: space-between;
  };

  .searchInputDesktop {
    display: none;
    width: 80%;

    @include breakpoint(medium, min) {
      display: block;
    }
    @include breakpoint(large, min) {
      width: 60%;
    }
  }
}

.leftSideOptionsWithSearch {
  @extend .leftSideOptions;

  width: 100%;
  flex: 1;
}


.infoIcon {
  display: none;

  @include breakpoint(large, max) {
    display: block;
    margin-right: 0.5rem;
    cursor: pointer;
  }
}

.filter-buttons {
  color: map-get($map: $colors, $key: copyDark);
  padding: .5rem .75rem;
  white-space: nowrap !important;
  display: none;

  @include breakpoint(medium, min) {
    display: flex;
  }
}

.filters-button-mobile-container {
  display: none;

  @include breakpoint(medium, max) {
    display: flex;
  }
}

.filters-button-mobile {
  color: map-get($map: $colors, $key: copyDark);
}