@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';
@import '../../mixins/box-shadow';
@import '../../mixins/truncate';

.body {
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h2 {
    @include heading();
  }

  @include breakpoint(medium, max) {
    padding: 2rem 1rem;
  }
}

.filter {
  align-items: center;

  &-genre-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &-item {
    padding: 0.5rem 1rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    border: 1px solid map-get($map: $colors, $key: lighterGrey);
    cursor: pointer;

    &:hover {
      background-color: lighten(map-get($map: $colors, $key: primary), 40%);
    }
  }

  &-item-select {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    background-color: lighten(map-get($map: $colors, $key: primary), 35%);
  }
}

.titles {
  &-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    flex: none;
    scroll-snap-align: start;
    min-height: 16rem;
  }

  &-listing {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: lighten(map-get($map: $colors, $key: primary), 35%);
    }
  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    img {
      min-width: 6.5rem;
      border-radius: map-get($map: $spacing, $key: borderRadius);
    }
  }

  &-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0.5rem;
  }

  &-titlename {
    @include truncate(2);
    @include type($color: copyDark, $font: copyBold, $size: base);
    text-align: left;
    line-height: 1.6;
  }

  &-details {
    @include type($color: copyDark, $font: copy, $size: small);
    text-align: left;
    line-height: 1.6;
  }

  @include breakpoint(large, max) {
    &-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
    }
  }

  @include breakpoint(small, max) {
    &-listing {
      flex-direction: column;
      gap: 0;
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  p {
    @include type($color: copy, $font: normal, $size: small);
  }

  &-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    button {
      width: 0.75rem;
      height: 0.75rem;
      padding: 0;
      border-radius: 50%;
      border: none;
      background-color: map-get($map: $colors, $key: lighterGrey);
      transition: background-color 0.3s;

      &.active {
        background-color: map-get($map: $colors, $key: primary);
      }
    }
  }
}
