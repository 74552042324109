@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/link';

.container {
  padding-left: map-get($spacing, 'sitePadding');
  padding-right: map-get($spacing, 'sitePadding');
  max-width: map-get($map: $spacing, $key: siteMaxWidth);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding-top: map-get($spacing, 'sitePadding');
  position: relative; // to hide behind search dropdown
  z-index: -2; // to hide behind search dropdown

  @include breakpoint(large, max) {
    max-width: none;
    overflow-x: scroll;
    margin-top: -1rem;
  }
}

.more-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  border-radius: 2rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-wrap: nowrap;

  @include breakpoint(large, min) {
    &:hover {
      background-color: rgba(map-get($map: $colors, $key: primary), 0.08);
      border-radius: 2rem;
    }
  }
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, 'sitePadding');

  &-item {
    padding: 5px 0 5px 25px;
    min-width: 200px;
    border-radius: 0.5rem;
  }
}

.dropdown-content {
  position: absolute;
  bottom: 20%;
  left: 100%;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s, transform 0.3s ease-in-out;
}

.dropdown-content.open {
  opacity: 1;
  transform: translateY(0);
}

.more-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow {
  @include breakpoint(large, max) {
    display: none !important;
  }
}

.visible-container {
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
  cursor: pointer;

  @include breakpoint(large, max) {
    flex: 0 0 auto;
    padding-right: map-get($spacing, 'sitePadding');
  }

  @include breakpoint(large, min) {
    &:hover {
      background-color: rgba(map-get($map: $colors, $key: primary), 0.08);
    }
  }
}

.link-items {
  @include type($size: small, $color: copy, $font: copy);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  transition: all 0.5s;
  position: relative;

  &-text {
    width: auto;

    @include breakpoint(large, max) {
      text-wrap: nowrap;
    }
  }

  &-dropdown {
    justify-content: flex-start;
    height: 2rem;

    &:hover {
      @include type($color: primary, $size: small);
    }

    @include breakpoint(large, max) {
      @include type($size: base, $color: copy, $font: copy);

      &:hover {
        @include type($color: primary);
      }
    }
  }

  &-active {
    @include type($size: small, $color: primary, $font: copyBold);
  }
}

.divider {
  border-right: 1px solid map-get($map: $colors, $key: borderLight);
  height: 2rem;
  width: 1px;
}
