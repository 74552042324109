@import "../config";
@import "../mixins/inputs";
@import "../mixins/typography";
@import "../mixins/box-shadow";

.editor-container {
  position: relative;
}

.editor-input {
  @include input-reset();

}

.editor-placeholder {
  @include type($size: base, $font: copy, $color: copyLight);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 0rem;
  left: 0rem;
  font-size: 1rem;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-toolbar {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: map-get($map: $colors, $key: foreground);
  @include box-shadow();
  border-top-left-radius: map-get($map: $spacing, $key: borderRadius);
  border-top-right-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
}

.toolbar-icon {
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: map-get($map: $colors, $key: copy);
  }

  path {
    fill: map-get($map: $colors, $key: copy);
  }

  &:hover {
    background-color: map-get($map: $colors, $key: background);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  &-active {
    background-color: map-get($map: $colors, $key: background);
    border-radius: map-get($map: $spacing, $key: borderRadius);

    svg {
      fill: map-get($map: $colors, $key: copyDark);
    }

    path {
      fill: map-get($map: $colors, $key: copyDark);
    }
  }
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 2rem;
  list-style-type: decimal;
  list-style-position: outside;
  @include copy();
  @include type($size: base, $font: copy, $color: copyDark);
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 2rem;
  list-style-type: disc;
  list-style-position: outside;
  @include copy();
  @include type($size: base, $font: copy, $color: copyDark);
}

.editor-listitem {
  margin-bottom: 0.5rem;
  @include type($size: base, $font: copy, $color: copyDark);
}

.editor-paragraph {
  @include copy();
  @include type($size: base, $font: copy, $color: copyDark);
}

.nested-listitem {
  margin-bottom: 0.5rem;
  list-style-type: circle;
  @include type($size: base, $font: copy, $color: copyDark);
}

.editor-text-bold {
  @include type($font: copyBold);

  // if its already italic, make it bold italic
  &.editor-text-italic {
    @include type($font: copyBoldItalic);
  }
}

.editor-text-italic {
  @include type($font: copyItalic);

  // if its already bold, make it bold italic
  &.editor-text-bold {
    @include type($font: copyBoldItalic);
  }
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-link {
  @include type($color: primary);
  text-decoration: none;
}

.editor-heading-h1 {
  @include type($size: jumbo, $font: heading, $color: copyDark);
  padding-bottom: 0.5rem;
  line-height: 3rem;
}

.editor-heading-h2 {
  @include type($size: large, $font: heading, $color: copyDark);
  padding-bottom: 0.5rem;
  line-height: 2.5rem;
}

.editor-heading-h3 {
  @include type($size: medium, $font: copyBold, $color: copyDark);
  padding-bottom: 0.5rem;
  line-height: 2rem;
}

.editor-quote {
  @include type($size: base, $font: copy, $color: copy);
  margin: 0;
  margin-left: 2rem;
  border-left-color: map-get($map: $colors, $key: copyLight);
  border-left-width: 0.25rem;
  border-left-style: solid;
  padding-left: 1rem;
  padding-bottom: 0.55rem;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

// styles for the floating link editor
.link-editor {
  @include box-shadow();
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 1.5rem);
  margin: 0.75rem 0.75rem;
  position: relative;
  @include input();
  background-color: map-get($map: $colors, $key: background);
}

.link-editor div.link-edit {
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-icon {
  height: 1rem;
  width: 1rem;

  svg {
    fill: map-get($map: $colors, $key: copyDark)
  }

  &-light {
    svg {
      fill: map-get($map: $colors, $key: copyLight)
    }

    &:hover {
      svg {
        fill: map-get($map: $colors, $key: copyDark)
      }
    }
  }
}

.edit-icon-container {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 0;
  top: -1.5rem;
  cursor: pointer;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  // background on hover
  &:hover {
    background-color: map-get($map: $colors, $key: foreground);
    border-radius: map-get($map: $spacing, $key: borderRadius);
    @include box-shadow();
  }
}

.composer-container {
  position: relative;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.save-button {
  width: 8rem;
  margin-left: 1rem;
}