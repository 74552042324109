@import '../config';
@import '../mixins/typography';
@import '../mixins/inputs';

.filter-buttons {
  color: map-get($map: $colors, $key: copyDark);
  padding: 0.5rem 0.75rem;
  white-space: nowrap !important;
  overflow-x: auto;
  max-width: fit-content;

  &-selected {
    border: solid 1px map-get($map: $colors, $key: primary);
    color: map-get($map: $colors, $key: copyDark) !important;
  }

  @include breakpoint(medium, min) {
    display: flex;
  }
}

.filter-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  cursor: pointer;
  flex-wrap: nowrap;
  background-color: transparent;
  padding: 0.5rem 1rem;
  margin: -0.5rem 0 -0.5rem -1rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none !important;
  }

  @include breakpoint(small, max) {
    overflow-x: auto !important;
  }
}

.filters-button-mobile-container {
  display: none;

  @include breakpoint(medium, max) {
    display: flex;
  }
}

.filters-button-mobile {
  color: map-get($map: $colors, $key: copyDark);
}

.dropdown-container {
  display: flex;
  justify-content: center;

  nav {
    padding: 2rem;
    padding-top: 0;

    a {
      @include copy(small);
      cursor: pointer;
      display: inline-block;
      width: 100%;
      margin-left: 0;
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }
}

.dropdown-menu-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
}

.parent-container {
  position: relative;
  z-index: 5;
}

.dropdown-menu {
  position: 'absolute';
  transform: translateX(2rem);

  &-format {
    transform: translateX(3rem);
  }

  &-categories {
    transform: translateX(4.5rem);
  }
}

.arrow {
  margin-top: 0.2rem;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;

  &-checkbox {
    display: flex;
    align-items: flex-start;
    @include checkbox();

    label span {
      padding: 0 0.25rem;
      @include type($font: copy, $size: small, $color: copy);
      line-height: 1.6;

      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }
  }
}

.clear-all-button {
  color: map-get($map: $colors, $key: primary);
  margin-left: 0.5rem;
  padding-top: 1rem;
  white-space: nowrap;
  max-width: fit-content;
}

.applied-filters-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  cursor: pointer;
  overflow-x: auto;
  flex-wrap: nowrap;
  background-color: transparent;
  padding: 0.5rem 1rem;
  margin: -0.5rem 0 -0.5rem -1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.applied-filters-container::-webkit-scrollbar {
  display: none !important;
}

.pill {
  display: flex;
  justify-content: space-between;
  background-color: $primary-100;
  align-items: center;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  width: fit-content;
  height: 2rem;
}

.desktop-sort {
  display: none;

  @include breakpoint(medium, min) {
    display: flex;
    justify-content: flex-end;
  }
}

.mobile-sort {
  display: flex;

  @include breakpoint(medium, min) {
    display: none !important;
  }
}

.sort-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 220px;

  @include breakpoint(small, max) {
    width: 200px;
  }
}

.sort-container select {
  padding: 0.78rem 0.7rem;

  @include breakpoint(small, max) {
    font-size: small;
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.pill-icon {
  display: flex;

  svg {
    width: 1rem !important;
    min-width: 0.5rem !important;
    margin-left: 0.5rem;
    margin-right: 0 !important;
    justify-content: space-between;
    align-items: center;
  }
}
